
import './App.css';
import AppMovedPage from "./pages/AppMoved";


function App() {

    return (
        <div className="App">
            <AppMovedPage/>
        </div>
    );
}

export default App;

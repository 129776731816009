import styles from './AppMoved.module.css';


const AppMovedPage = () => {
    return (
        <div className={styles.container}>
            <span className={styles.text}>
                Система Quantum отключена.<br/>
                Далее все заявки необходимо делать в системе{' '}
                <a className={styles.link} href="https://fms.megamarket.tech/">FMS</a>.
            </span>
        </div>
    );
};

export default AppMovedPage;